<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>



        <div class="row cont_documenti">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>


                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class="col_n">N.</div>
                                        </th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            Date</th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            <div style="width:133px!important">Title</div>
                                        </th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            <div style="width:133px!important">Sender</div>
                                        </th>

                                        <th class='all'>
                                            <div style="width:133px!important">Name Receiver</div>
                                        </th>

                                        <th class='all'>
                                            <div style="width:133px!important">Surname Receiver</div>
                                        </th>

                                        <th class='all'>
                                            <div style="width:133px!important">Mail Receiver</div>
                                        </th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            <div style="width:85px!important;text-align: center">Saved
                                            </div>
                                        </th>


                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            <div style="width:85px!important;text-align: center">Phone
                                                Verify</div>
                                        </th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            <div style="width:40px!important;text-align: center">Sent
                                            </div>
                                        </th>



                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            <div style="width:50px!important;text-align: center">Signed
                                            </div>
                                        </th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            <div style="width:100px!important">Date Signed
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="12">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/docsign_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="" id="fldSearch">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_docsign.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_docsign.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';
import _ from 'lodash';

import Vue from "vue";

import VueSignaturePad from 'vue-signature-pad';

import apimessaggi from "../utils/messaggi/apimessaggi";

Vue.use(VueSignaturePad);

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var a2 = window.$cookies.get("a");
        var b2 = window.$cookies.get("b");

        if ((a2 == "N") && (b2 == "N")) {

            this.$root.$children[0].buttonsInfoUser = [];
            this.$root.$children[0].totalMsg = "";
        }


        window.myVal = "";

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        window.curSrcText = "";

        window.curStatus = 0; // default non firmati


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {






            $('body').on('click', '#cont_idresource', function (event) {
                console.log(event);

                pointerVue.sheetRisorse = true;

            });

            $('body').on('click', '.selrisorse', function () {

                window.$cookies.set("sel_filter_risorse", window.company, "9y");

                router.push({
                    path: "/risorse"
                });

            });


            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);


            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



        });


        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);



            switch (data) {


                case "btn_add":

                    router.push({
                        path: "/editDocument"
                    });


                    break;

                case "btn_delete":

                    this.deleteRow();


                    break;

                case "btn_view_admin":

                    console.log("CUR ITEM ROW: ", this.currItemRow);

                    window.currentItemRow = this.currItemRow;

                    router.push({
                        path: "/viewAttachment"
                    });


                    break;


                case "btn_send":

                    this.sendMessage();


                    break;



                case "btn_verify":

                    window.selRowId = 0;
                    var check_mobile = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            window.selRowId = $(this).attr("data-id")
                            check_mobile = $(this).attr("check-mobile")


                        }

                    });

                    window.mailDest = "";

                    if (check_mobile == 0) {

                        router.push({
                            path: "/verifiyMobile"
                        });


                    }

                    break;

                case "btn_view":

                    console.log("CUR ITEM ROW: ", this.currItemRow);

                    window.currentItemRow = this.currItemRow;


                    window.emailDestination = window.currentItemRow.dest;
                    window.prefixMobileDestination = window.currentItemRow.prefix_mobile;
                    window.mobileDestination = window.currentItemRow.number_mobile;

                    router.push({
                        path: "/vediAllegato"
                    });


                    break;

                case "btn_download_attach":

                    var v_id = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            v_id = $(this).attr("data-id")

                        }

                    });

                    this.showSpinner = true;
                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {




                        var url_call = this.getUrlDomain("AlertMsgDownAttachDocSign") + "?v_id=" + v_id;

                        console.log("URL TO CALL: ", url_call);

                        window.open(url_call, "_blank");


                        setTimeout(() => {

                            this.showSpinner = false;
                            this.$root.$children[0].showProgress = false;

                            this.setupButtons();

                            window.table.ajax.reload();



                        }, 1000);


                    }, 500);

                    break;

                default:
                    break;

            }
        });


        window.start = 0;

        var pointerVue = this;

        pointerVue.setupButtons();

        pointerVue.setupGrid();



        var a3 = window.$cookies.get("a");
        var b3 = window.$cookies.get("b");

        if ((a3 == "N") && (b3 == "N")) {

            var view_alert_grid_doc = window.$cookies.get("view_alert_grid_doc");

            if (view_alert_grid_doc == 1) {

                window.$cookies.set("view_alert_grid_doc", 0, "9y");

                setTimeout(() => {

                    this.$swal({
                        icon: "warning",
                        text: "Select the document and click the verify phone button to verify or view to see and sign",
                        showConfirmButton: true,
                        confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_docsign.png' />",
                    });

                }, 1000);


            }



        }



        if ((a3 == "N") && (b3 == "N")) {


            // se arrivo dalla mail del doc firmato lo seleziono
            setTimeout(() => {

                //this.selectRowById(119);

                var id_doc_signed = window.$cookies.get("is_doc_signed");



                console.log("ID DOC SIGNED: ", id_doc_signed);

                if (id_doc_signed != null) // arrivo dalla mail del documento firmato
                {

                    if (id_doc_signed != 0) {

                        pointerVue.selectRowById(id_doc_signed);

                        // window.$cookies.set("is_doc_signed", 0, "9y");

                    }


                }



            }, 1000);


        }




    },
    data: () => ({

        currItemRow: {},

        risorse: [],

        selRisorsa: "",


        sheetRisorse: false,

        valueTypeUser: "DIP",


        valueOtpDelete: "",

        sheetAziende: false,
        search: "",

        textSearch: "",

        aziende: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredRisorse() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.search) return this.risorse;
                return (item.resource.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


    },


    methods: {

        selectRowById: function (v_id) {

            var that = this;

            console.log("SELECT_ROW_BY_ID");

            console.log("IS DOC SELECTED: ", v_id);

            console.log("NUM ELEMENTS: ", $(".val_status").length);


            $(".val_status").each(function (index) {

                console.log(index);

                console.log("ID: ", $(this).attr("data-id"));

                if (parseInt($(this).attr("data-id")) == parseInt(v_id)) // ho trovato il documento firmato nella griglia
                {

                    console.log("FOUND");

                    // imposto il checkbox
                    $(this).prop("checked", true);

                    // seleziono la riga del documento
                    var v_row = $(this).parent().parent();
                    $(v_row).addClass("active-row");

                    var item = window.table.row(v_row).data();

                    console.log("ITEM ROW SEL ROW: ", item);

                    that.currItemRow = item;

                    that.hideShowButtonsSelRow();

                }


            });


        },

        sendMessage: async function () {

            var that = this;


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.sendMessage(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMessage", res);

                that.$swal({
                    icon: "success",
                    text: "Messages sent successfully",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(function () {

                    window.table.ajax.reload();

                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        deleteRow: async function () {


            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Delete",
                    html: "Are you sure you want to delete the selected items ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_docsign.png' />",
                    cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_docsign.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {


                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);




                        try {
                            var response = that.$api.deleteMessageMulti(
                                v_token,
                                ids
                            );

                            console.log("res from deleteMessageMulti", response);

                            that.$swal({
                                icon: "success",
                                text: "Data deleted successfully",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.setupButtons();

                                window.table.ajax.reload();


                            }, 200);

                        } catch (error) {
                            console.log("ERROR DELETE: ", error);
                        }

                    }

                });


        },


        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/" + nome_metodo;

        },


        manageClickRisorse: async function (risorsa) {

            console.log("RIS SEL: ", risorsa);

            this.sheetRisorse = false;

            this.selRisorsa = risorsa.resourceid;


        },

        btnCancelTypeUser: function () {

            this.$modal.hide('popupOtpTipoUtente');

        },

        btnConfirmTypeUser: function () {

            window.$cookies.set("type_user_ins", this.valueTypeUser, "9y");

            router.push({
                path: "/editUser/0"
            });

        },




        btnResetSearch: function () {

            window.curSrcText = "";



            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },



        btnCancelMailDataUser: function () {

            tinymce.remove();

            this.$modal.hide('popupMail');

        },

        btnConfirmMailDataUser: function () {

            var that = this;

            var errore = "";

            var txt_body = tinymce.activeEditor.getContent();

            console.log("BODY: ", txt_body);

            if (txt_body.length == 0) {
                errore = "Specificare un testo per la mail";
            }


            if (errore == "") {

                console.log("ok");

                that.sendMailDataUser();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }


        },


        syncAziende: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                that.$root.$children[0].showProgress = false;

                var azi_temp = JSON.parse(window.$cookies.get("companyUser"));

                var azi_array = [];

                azi_array.push({
                    companyid: "",
                    fld_name: "Tutte"
                });

                for (var x = 0; x < azi_temp.length; x++) {

                    azi_array.push({
                        companyid: azi_temp[x].companyid,
                        fld_name: azi_temp[x].fld_name
                    });

                }

                // that.aziende = JSON.parse(window.$cookies.get("companyUser"));

                that.aziende = azi_array;

                // console.log("AZIENDE: ", that.aziende);


            }, 300);


        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.company = azienda.companyid;

            var v_azienda_id = azienda.companyid;

            if (v_azienda_id == -1) {

                v_azienda_id = window.$cookies.get("cid");

            }

            window.$cookies.set("sel_filter_azienda_utenti", v_azienda_id, "9y");

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },



        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        hideShowButtonsSelRow: function () {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            var tot = 0;

            var check_mobile = 0;
            var save_doc = 0;
            var signature = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                    check_mobile = $(this).attr("check-mobile");
                    save_doc = $(this).attr("save-doc");
                    signature = $(this).attr("signature");

                }

            });



            setTimeout(() => {

                var pulsantis = [];

                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                    if (tot == 0) {

                        pulsantis.push({
                            text: "",
                            icon: "mdi-message-plus",
                            link: "/dash",
                            id: "btn_aziende_demo",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/add_docsign.png",
                            title: "Aggiungi",
                            width: 30

                        });

                    }


                    if (tot > 0) {


                        pulsantis.push({
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/elimina_docsign.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30

                        });



                    }

                    if (tot == 1) {

                        if (signature == 1) {


                            if (save_doc == 1) {

                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/download_docsign.png"),
                                    link: "/info",
                                    id: "btn_download_attach",
                                    disabled: false,
                                    title: "Download",
                                    width: 25

                                });

                            }

                        }


                        if (signature == 0) {



                            pulsantis.push({
                                text: "",
                                icon: "mdi-eye",
                                link: "/dash",
                                id: "btn_view_admin",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/view_docsign.png",
                                title: "View",
                                width: 45

                            });

                            pulsantis.push({
                                text: "",
                                icon: "mdi-eye",
                                link: "/dash",
                                id: "btn_send",
                                disabled: false,
                                image: this.getUrlDomain("public/_lib/img/invio-docsign.png"),
                                title: "Send",
                                width: 30

                            });




                        }




                    }




                }

                if ((a == "N") && (b == "N")) {

                    if (tot == 1) {


                        if (check_mobile == 1) {

                            pulsantis.push({
                                text: "",
                                icon: "mdi-eye",
                                link: "/dash",
                                id: "btn_view",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/view_docsign.png",
                                title: "View",
                                width: 45

                            });


                        }

                        if (check_mobile == 0) {

                            pulsantis.push({
                                text: "",
                                icon: "mdi-eye",
                                link: "/dash",
                                id: "btn_verify",
                                disabled: false,
                                image: this.getUrlDomain("public/_lib/img/verify_docsign2.png"),
                                title: "Verify Mobile",
                                width: 30

                            });

                        }


                    }




                }





                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);








        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {


            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            var filtri = `<img src='/2sign/img/refresh_docsign.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><div style="transform: translateY(-1px);margin-left:7px" title='Stati'>
                <ion-select cancel-Text="Chiudi" data-id="0" value="0" class="selstato" interface="action-sheet" placeholder=" " title='Stati'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option value='1'>Firmati</ion-select-option><ion-select-option value='0'>Non Firmati</ion-select-option></div>`;

            if ((a == "N") && (b == "N")) {
                filtri = ``;
            }

            var pointerVue = this;


            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    name: 'sel_row',
                    width: '1%'
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },


                {
                    data: 'created_at',
                    orderable: false,
                    name: 'created_at',
                    width: '3%',

                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }

                },

                {
                    data: 'title_formatted',
                    orderable: false,
                    name: 'title_formatted',
                    width: '5%',
                },

                {
                    data: 'email_mitt',
                    orderable: false,
                    name: 'email_mitt',
                    width: '5%',
                },

                {
                    data: 'nome',
                    orderable: false,
                    name: 'nome',
                    width: '7%'
                },

                {
                    data: 'cognome',
                    orderable: false,
                    name: 'cognome',
                    width: '7%'
                },


                {
                    data: 'email_dest',
                    orderable: false,
                    name: 'email_dest',
                    width: '4%'
                },

                {
                    data: 'save_doc',
                    orderable: false,
                    name: 'save_doc',
                    width: '1%'
                },


                {
                    data: 'check_mobile',
                    orderable: false,
                    name: 'check_mobile',
                    width: '2%'
                },

                {
                    data: 'sent',
                    orderable: false,
                    name: 'sent',
                    width: '1%'
                },



                {
                    data: 'is_signatured',
                    orderable: false,
                    name: 'is_signatured',
                    width: '1%'
                },

                {
                    data: 'date_hour_signed',
                    orderable: false,
                    name: 'date_hour_signed',
                    width: '3%',

                    render: function (data, row) {
                        console.log(data, row);

                        console.log("DATA: ", data);

                        var html = "";

                        if (data != null) {

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            html = "<div class='font-row-grid-datetime-mobile3'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        }

                        return html;
                    }


                },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,



                    ajax: {
                        url: pointerVue.getUrlDomainApi("DocSignGetDocumentsApi") + '?username=' + v_token + "&productid=docsign",
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;

                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);


                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomainApi("DocSignGetDocumentsApi") + '?username=' + v_token + "&productid=docsign",
                                data: window.valori,
                                success: function (data) {
                                    // $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Documents</div>
                    ` + filtri + `

                </div>
                <div><img src='/2sign/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selstato").on("ionChange", function (opt) {
                    console.log(opt);


                    window.curStatus = this.value;


                    pointerVue.$root.$children[0].showProgress = true;

                    pointerVue.setupButtons();

                    window.table.ajax.reload();

                });


                $(".selaggiorna").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();



                });


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                    setTimeout(() => {

                        $("#fldSearch").focus();

                    }, 200);

                });




                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_documenti th', function () {


                    console.log("TH-ACTIVE: ", $(this));


                    $(this).removeClass("sorting");



                });

                $('body').on('click', '.cont_documenti tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        pointerVue.currItemRow = row_data;

                        if (row_data != null) {

                            pointerVue.currItemRow = row_data;


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];


                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     width: 35
                        // },

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_edit_docsign.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_docsign.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     width: 35
                        // },


                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {
            setTimeout(() => {

                var pulsantis = [];

                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                    pulsantis.push({
                        text: "",
                        icon: "mdi-message-plus",
                        link: "/dash",
                        id: "btn_add",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/add_docsign.png",
                        title: "Add",
                        width: 30

                    });
                }

                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },



        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.contpopuptipoutente .v-label {
    font-size: 10px !important;
}


#fldValueOtpDelete {
    text-align: center;
    font-size: 20px
}


.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_documenti {
    overflow-x: auto !important;
}

.cont_documenti #myTable {
    margin-right: 3px;
    width: 1285px !important;
}

.cont_documenti table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_documenti table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_documenti th {
    padding-left: 5px !important;
}

.cont_documenti ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_documenti .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_documenti #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #000082;

}

.cont_documenti div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_documenti #myTable_wrapper {
    padding-top: 0px;
    font-size: 11px;
    color: black;
    min-width: initial;
    min-width: 1300px;
    max-width: 1300px;
    width: 1300px;
}

.cont_documenti .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 2vw
}


.cont_documenti .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_documenti .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_documenti .selrisorse {
    max-width: 25px;
    min-height: 25px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 25px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cont_documenti .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_documenti .checkall {
    width: 13px;
    height: 13px;
}


.cont_documenti .val_status {
    width: 10px !important;
}

.cont_documenti .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_documenti .selaggiorna {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_documenti .selstato {
    max-width: 25px;
    min-height: 25px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 29px;
    background-repeat: no-repeat;
    background-image: url(/2sign/img/filtro_stati_docsign.png);
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 1px;
}




.cont_documenti .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 86px;
    padding-left: 10px;
    justify-content: inherit;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}



@media screen and (max-width: 960px) {

    .cont_documenti .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_documenti #myTable {
        /* margin-top:54px; */
    }

    .cont_documenti #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_documenti #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_documenti #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_documenti .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }


    .cont_documenti #myTable_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
    }

    .cont_documenti .cl_num {
        margin-left: -2px !important;
    }


    .cont_documenti .cl_checkall {
        padding-left: 2px;
        padding-top: 2px;
    }



}
</style>
