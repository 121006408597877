<template>
    <v-container class="w-container-conf-mail-docsign v-overflow editConfMailDocSign"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">




        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
                background-color: white!important;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;                
                min-width:100vw;
                border-color:#000082;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>


            <v-row>


                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="conf_mail.email" label="E-Mail">
                    </v-text-field>

                </v-col>

            </v-row>



        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_docsign.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende_docsign.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apimessaggi from "../utils/messaggi/apimessaggi";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var pointerVue = this;


        this.setupButtons();


        if (this.$route.params.id > 0) {

            this.titolo = "Modifica E-Mail";

            setTimeout(() => {
                this.syncConfMail();
            }, 100);

        

        }


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/confMailDocSignedDoc"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            // $('body').on('ionChange', '.is_admin_company', function (event) {
            //     console.log(event);

            //     pointerVue.utente.id_admin_company = this.value;

            // });




            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({


        conf_mail: {
            email: ""
        },


        valoresino: [{ id: "S", name: "Si" }, { id: "N", name: "No" }],

        changePsw: false,

        titolo: "Aggiungi E-Mail",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: { is_admin_company: "N" },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],


        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        validateEMail: function (email) {
            const regex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
            return regex.test(email);
        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.idcompany_logo = azienda.companyid;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/salva_docsign.png",
                    //class: "inverted",
                    title: "Salva",
                    width: 30

                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/indietro_docsign.png",
                    //class: "inverted",
                    width: 30

                },




                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var errore = "";

            if (that.conf_mail.email == "") {
                errore = "Specificare una mail";
            }

            if (errore == "") {
                if (!that.validateEMail(that.conf_mail.email)) {
                    errore = "E-Mail non valida";
                }
            }

            if (errore == "") {

                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }
        },

        saveData: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apimessaggi.saveConfMail(
                that.$route.params.id,
                that.conf_mail.email
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveConfMail", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/confMailDocSignedDoc"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncConfMail: async function () {

            var that = this;

         

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getConfMail(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConfMail", res);

                that.conf_mail = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);


                try {

                    that.aziende = res.data.aziende;

                    that.prefissi = res.data.prefix;

                    that.gruppi = res.data.models;


                    setTimeout(() => {

                        //that.utente.idcompany_logo = window.$cookies.get("sel_filter_azienda_utenti");
                        that.utente.idcompany_logo = window.$cookies.get("sel_filter_azienda_utenti");

                        $(".companyid").val(window.$cookies.get("sel_filter_azienda_utenti"));

                    }, 200);


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-conf-mail-docsign {
    width: 500px;
    padding: 0px;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editConfMailDocSign .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editConfMailDocSign .v-input {
    font-size: 1.2em;
}

.editConfMailDocSign .v-select {
    font-size: 1.2em;
}

.editConfMailDocSign .v-label {
    font-size: 1em;
}


.editConfMailDocSign .cl-checkbox i {
    font-size: 13px !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
