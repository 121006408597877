import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import reservate from './components/reservate.vue'
import editFirma from './components/editFirma.vue'
import firme from './components/firme.vue'
import sign from './components/sign.vue'
import signtest from './components/signtest.vue'
import documents from './components/documents.vue'
import sendDoc from './components/sendDoc.vue'
import reloadComponent from './components/reloadComponent.vue'
import discover from './components/discover.vue'
import discoversettings from './components/discoversettings.vue'
import reservateSignDoc from './components/reservateSignDoc.vue'
import editDocument from './components/editDocument.vue'
import editDocumentSetSign from './components/editDocumentSetSign.vue'
import vediAllegato from './components/vediAllegato.vue'
import verifyEmailAndMobile from './components/verifyEmailAndMobile.vue'
import viewAttachment from './components/viewAttachment.vue'
import initDocuments from './components/initDocuments.vue'
import verifiyMobile from './components/verifiyMobile.vue'


import confMailDocSignedDoc from './components/confMailDocSignedDoc.vue'

import editConfMail from './components/editConfMail.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/discover',
            name: 'discover',
            component: discover
        },
        {
            path: '/discoversettings',
            name: 'discoversettings',
            component: discoversettings
        },

        

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props: true
        },

        {
            path: '/sign/:id',
            name: 'sign',
            component: sign,
            props: true
        },
        {
            path: '/signtest/:id',
            name: 'signtest',
            component: signtest,
            props: true
        },


        {
            path: '/settings',
            name: 'settings',
            component: settings
        },


        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial
        }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/editFirma/:id',
            name: 'editFirma',
            component: editFirma
        }

        ,
        {
            path: '/firme',
            name: 'firme',
            component: firme
        }

        ,
        {
            path: '/documents',
            name: 'documents',
            component: documents
        }

        ,
        {
            path: '/sendDoc',
            name: 'sendDoc',
            component: sendDoc
        }

        ,
        {
            path: '/reloadComponent/:id',
            name: 'reloadComponent',
            component: reloadComponent,
            props: true
        }

        ,
        {
            path: '/reservateSignDoc/:id',
            name: 'reservateSignDoc',
            component: reservateSignDoc,
            props: true
        }



        ,
        {
            path: '/editDocument',
            name: 'editDocument',
            component: editDocument
        }

        ,
        {
            path: '/editDocumentSetSign',
            name: 'editDocumentSetSign',
            component: editDocumentSetSign
        }

        ,
        {
            path: '/vediAllegato',
            name: 'vediAllegato',
            component: vediAllegato
        }

        ,
        {
            path: '/verifyEmailAndMobile',
            name: 'verifyEmailAndMobile',
            component: verifyEmailAndMobile
        }

        ,
        {
            path: '/viewAttachment',
            name: 'viewAttachment',
            component: viewAttachment
        }

        ,
        {
            path: '/initDocuments',
            name: 'initDocuments',
            component: initDocuments
        }

        ,
        {
            path: '/verifiyMobile',
            name: 'verifiyMobile',
            component: verifiyMobile
        }




        ,
        {
            path: '/confMailDocSignedDoc',
            name: 'confMailDocSignedDoc',
            component: confMailDocSignedDoc
        }

        ,
        {
            path: '/editConfMail/:id',
            name: 'editConfMail',
            component: editConfMail,
            props: true
        }
        
                

    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)

})


export default router
